var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"single-line":"","append-icon":"mdi-magnify","label":_vm.$t('Search'),"hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-icon',{attrs:{"slot":"append"},on:{"click":function($event){return _vm.searchItem()}},slot:"append"},[_vm._v(" mdi-magnify")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.seasons,"items-per-page":_vm.itemsPerPage,"options":_vm.options,"no-data-text":_vm.$t('No data available'),"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Active ? _vm.$t("Yes") : _vm.$t("No"))+" ")]}},{key:"item.ActiveSalesAgent",fn:function(ref){
var item = ref.item;
return [(item.Active)?_c('v-switch',{on:{"change":function($event){return _vm.putItem(item)}},model:{value:(item.ActiveSalesAgent),callback:function ($$v) {_vm.$set(item, "ActiveSalesAgent", $$v)},expression:"item.ActiveSalesAgent"}}):_vm._e()]}},{key:"item.ActiveB2B",fn:function(ref){
var item = ref.item;
return [(item.Active)?_c('v-switch',{on:{"change":function($event){return _vm.putItem(item)}},model:{value:(item.ActiveB2B),callback:function ($$v) {_vm.$set(item, "ActiveB2B", $$v)},expression:"item.ActiveB2B"}}):_vm._e()]}},{key:"item.DraftB2C",fn:function(ref){
var item = ref.item;
return [(item.Active)?_c('v-switch',{on:{"change":function($event){(item.ActiveB2C = false), _vm.putItem(item)}},model:{value:(item.DraftB2C),callback:function ($$v) {_vm.$set(item, "DraftB2C", $$v)},expression:"item.DraftB2C"}}):_vm._e()]}},{key:"item.ActiveB2C",fn:function(ref){
var item = ref.item;
return [(item.Active)?_c('v-switch',{on:{"change":function($event){(item.DraftB2C = false), _vm.putItem(item)}},model:{value:(item.ActiveB2C),callback:function ($$v) {_vm.$set(item, "ActiveB2C", $$v)},expression:"item.ActiveB2C"}}):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mx-2 py-2",attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('span',{staticClass:"mr-4 grey--text"},[_c('small',[_vm._v(_vm._s(_vm.$t("Page"))+" "+_vm._s(_vm.getPage)+" "+_vm._s(_vm.$t("out of"))+" "+_vm._s(_vm.getAmountOfPages))])]),_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":_vm.hasPrevPage,"fab":"","x-small":""},on:{"click":function($event){return _vm.formerPage()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":_vm.hasNextPage,"fab":"","x-small":""},on:{"click":function($event){return _vm.nextPage()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }