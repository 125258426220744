<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>

      <v-col cols="4">
        <v-text-field
          v-model="search"
          single-line
          append-icon="mdi-magnify"
          :label="$t('Search')"
          hide-details
          clearable
        >
          <v-icon slot="append" @click="searchItem()">
            mdi-magnify</v-icon
          ></v-text-field
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="seasons"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
      class="elevation-1"
      :no-data-text="$t('No data available')"
      hide-default-footer
    >
      <template v-slot:[`item.Active`]="{ item }">
        {{ item.Active ? $t("Yes") : $t("No") }}
      </template>
      <template v-slot:[`item.ActiveSalesAgent`]="{ item }">
        <v-switch
          v-if="item.Active"
          v-model="item.ActiveSalesAgent"
          @change="putItem(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.ActiveB2B`]="{ item }">
        <v-switch
          v-if="item.Active"
          v-model="item.ActiveB2B"
          @change="putItem(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.DraftB2C`]="{ item }">
        <v-switch
          v-if="item.Active"
          v-model="item.DraftB2C"
          @change="(item.ActiveB2C = false), putItem(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.ActiveB2C`]="{ item }">
        <v-switch
          v-if="item.Active"
          v-model="item.ActiveB2C"
          @change="(item.DraftB2C = false), putItem(item)"
        ></v-switch>
      </template>
      <template v-slot:footer>
        <v-row class="mx-2 py-2" align="center" justify="center">
          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            <small
              >{{ $t("Page") }} {{ getPage }} {{ $t("out of") }}
              {{ getAmountOfPages }}</small
            >
          </span>
          <v-btn
            :disabled="hasPrevPage"
            fab
            x-small
            class="mr-1"
            @click="formerPage()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="hasNextPage"
            fab
            x-small
            class="ml-1"
            @click="nextPage()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import request from "../../request";
export default {
  data() {
    return {
      options: {},
      seasons: [],
      search: "",
      page: 1,
      count: 10,
      start: 0,
      amountOfPages: 1,
      itemsPerPage: 10,
      timer: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Collection"),
          align: "start",
          sortable: true,
          value: "Name",
        },
        {
          text: this.$t("Active"),
          sortable: false,
          value: "Active",
        },
        {
          text: this.$t("Active Sales Agent"),
          sortable: false,
          value: "ActiveSalesAgent",
        },
        {
          text: this.$t("Active B2B"),
          sortable: false,
          value: "ActiveB2B",
        },
        {
          text: this.$t("B2C Draft"),
          sortable: false,
          value: "DraftB2C",
        },
        {
          text: this.$t("Active B2C"),
          sortable: false,
          value: "ActiveB2C",
        },
      ];
    },
    getPage: {
      get() {
        return this.page;
      },
      set(newItem) {
        this.page = newItem;
      },
    },
    hasPrevPage() {
      if (this.getPage == 1) {
        return true;
      }
      return false;
    },
    hasNextPage() {
      if (this.getPage < this.amountOfPages) {
        return false;
      }
      return true;
    },
    getAmountOfPages: {
      get() {
        if (this.amountOfPages == 0) {
          return 1;
        }
        return this.amountOfPages;
      },
      set(newItem) {
        this.amountOfPages = newItem;
      },
    },
  },
  watch: {
    search: function (params) {
      params ? params : (params = "");
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.resetPaging();
        this.getSeasons({
          start: this.start,
          count: this.count,
          search: params,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }, 800);
    },
    options: {
      handler() {
        this.getSeasons({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      },
      deep: true,
    },
  },
  created() {
    this.getSeasons({
      start: this.start,
      count: this.count,
      search: this.search ? this.search : "",
      sort: "",
      sortDescending: false,
    });
  },
  methods: {
    putItem(item) {
      request.put(
        "/api/kdesign/season",
        {
          ...item,
        },
        () => {}
      );
    },
    resetPaging() {
      this.page = 1;
      this.start = 0;
      this.count = 10;
    },
    getSeasons(items) {
      request.get(
        "/api/kdesign/seasons?start=" +
          items.start +
          "&count=" +
          items.count +
          "&sort=" +
          items.sort +
          "&sortDescending=" +
          items.sortDescending +
          "&search=" +
          items.search,
        null,
        (res) => {
          this.seasons = res;
        }
      );
      this.getSeasonsCount(items);
    },
    getSeasonsCount(items) {
      request.get(
        "/api/kdesign/seasonscount?search=" + items.search,
        null,
        (res) => {
          this.amountOfPages = Math.ceil(res.item / this.itemsPerPage);
        }
      );
    },
    nextPage() {
      this.page++;
      this.start = this.start + this.itemsPerPage;
      this.count = this.count + this.itemsPerPage;
      request.get(
        "/api/kdesign/seasons?start=" +
          this.start +
          "&count=" +
          this.count +
          "&search=" +
          this.search,
        null,
        (res) => {
          this.seasons = res;
        }
      );
    },
    checkForSearch() {
      return this.search !== "" ? `?search=${this.search}` : "";
    },
    formerPage() {
      this.page--;
      this.start = this.start - this.itemsPerPage;
      this.count = this.count - this.itemsPerPage;
      request.get(
        "/api/kdesign/seasons?start=" +
          this.start +
          "&count=" +
          this.count +
          "&search=" +
          this.search,
        null,
        (res) => {
          this.seasons = res;
        }
      );
    },
  },
};
</script>

<style>
</style>